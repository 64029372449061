$("#domain_result_whois").hide();
$(".bot-buscar-dominio").on('click', function () {
	$('.bot-buscar-dominio').prop('disabled', true);
	var boton = $(".bot-buscar-dominio").html();
	$(".bot-buscar-dominio").html('<img width="20px" src="https://clientes.latincloud.com/assets/checkout/img/loading.gif">');
	var dominio = $('.inputDomain').val();
	grecaptcha.ready(async function () {
		grecaptcha.execute('6LdUBKkZAAAAAO-KFi9aUGK_rXOaQOy0n73h90W6', { action: 'validate_captcha' }).then(function (token) {
			$.get('/buscarDominio/' + dominio + '/' + token + '', '', function (data, statusText, xhr) {
				$("#domain_result").html(data);
				$(".bot-buscar-dominio").html(boton);
				$('.bot-buscar-dominio').prop('disabled', false);
			});
		});
	});
});

jQuery('.inputDomain').keypress(function (e) {
	if (e.which == 13) {//Enter key pressed
		jQuery('.bot-buscar-dominio').click();//Trigger search button click event
	}
});
$(".search-whois-trigger").on('click', function () {
	searchWhois();
});
$('.inputWhoisDomain').on('keypress', function (e) {
	if (e.which === 13) { // Verifico si presiono Enter
		searchWhois();
	}
});

function searchWhois() {
	$('.search-whois-trigger').prop('disabled', true);
	var boton = $(".search-whois-trigger").html();
	$(".search-whois-trigger").html('<img width="20px" src="https://clientes.latincloud.com/assets/checkout/img/loading.gif">');
	var dominio = $('.inputWhoisDomain').val();
	grecaptcha.ready(async function () {
		await grecaptcha.execute('6LdUBKkZAAAAAO-KFi9aUGK_rXOaQOy0n73h90W6', { action: 'validate_captcha' }).then(function (token) {
			$.get('/whois/' + dominio + '/' + token + '', function (data) {
				$("#domain_result_whois").show();
				if (data.msj == 'Error') {
					$("#domain_result_whois").html('Error de Captcha');
				} else {
					$("#domain_result_whois").html(data.whois.whois);
				}
				$('.search-whois-trigger').prop('disabled', false);
				$(".search-whois-trigger").html(boton);
				$(".search-whois-trigger").html('Buscar');
			});
		});
	});
	
}